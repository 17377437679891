import React from "react";
import Header from "../../Components/Header";
import "./Home.scss"
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import Footer from "../Footer";

const Home = () =>{
    return(
        <div className="Home">
            <Header/>
            <First/>
            <Second/>
            <Third/>
            <Fourth/>
            <Fifth/>
            <Footer/>
        </div>
    )
}

export default Home