import React from "react";
import Header from '../../Components/Header'
import Footer from "../Footer";
import { AchievementsIllustration } from "../../Assets";
import { Project1,Project2,Project3,Project4,Project5,Project6,Project7,Project8,Project9,Achievement,achievebg } from "../../Assets";
import "./Achievements.scss"
import Box from "./Box";

const Achievements = () =>{
    
    const datas = [
        {
            team:'Top Projects',
            college:"Bannari Amman Institute Of Technology",
            port:342,
            mern:310,
            train:350,
            days:15,
            pro:[
                {
                    img:Project1,
                    team:[
                        {
                            name:"NIFAZ AHAMED C S",
                            site:'https://lostnfound-sfq6.onrender.com/',
                            linked:'https://www.linkedin.com/in/nifaz/',
                        }
                    ]
                },
                {
                    img:Project2,
                    team:[
                        {
                            name:"BHARATH V",
                            site:'https://question-forum.cyclic.app/',
                            linked:'https://www.linkedin.com/in/bharath-v-536990245/',
                            
                        }
                        
                    ]
                },
                {
                    img:Project3,
                    team:[
                        {
                            name:"VIMALRAJ S",
                            site:'https://bitmenu.netlify.app/',
                            linked:'https://www.linkedin.com/in/vimalrajs2002/',
                        }
                    ]
                }
            ]
        },
        {
            team:'Top Projects',
            college:"Kongu Engineering College",
            port:233,
            mern:200,
            train:250,
            days:7,
            pro:[
                {
                    img:Project4,
                    team:[
                        {
                            name: "SANTHOSH KUMAR S",
                            site:'https://docketweb.netlify.app/'
                        }
                       
                    ]
                },
                {
                    img:Project5,
                    team:[
                        {
                            name:"ROHITH M R",
                            site:'https://rohithmr02.github.io/blockgame/'
                        }
                    ]
                },
                {
                    img:Project6,
                    team:[
                        {
                            name:"MOSES R",
                            site:'https://weather-2rxd.onrender.com/'
                        }
                    ]
                }
            ]
        },
        {
            team:'Top Projects (Team Activity)',
            college:"Sri Eshwar College Of Engineering",
            port:322,
            mern:'50+',
            train:350,
            days:15,
            pro:[
                {
                    img:Project7,
                    team:[
                        {
                            name:"Shankar K",
                            site:'https://book-my-show-lite.vercel.app/'
                        },
                        {
                            name:"Jeeththenthar la",
                        },
                        {
                            name: "Srikanth M"
                        }
                    ]
                },
                {
                    img:Project8,
                    team:[
                        {
                            name:"S. Naveen",
                            site:"https://netflixclone-mern.netlify.app/"
                        },
                        {
                            name:"K. Kaliraj"
                        },
                        {
                            name:"K. N. Sanjay"
                        }
                        
                    
                    ]
                },
                {
                    img:Project9,
                    team:[
                        {
                            name:"Fardheen N",
                            linked:'https://www.linkedin.com/in/ACoAADgBRtQBqlYcH71CPCIoRKsHMqHYMgdcifs',
                            site:'https://moviehub23.netlify.app/'
                        },
                        {
                            name:"Mohammed Waseem",
                            linked:'https://www.linkedin.com/in/ACoAADkPax4B-Esq3hqaeWcDcFSGZ8ySmAIaJxQ'
                        },
                        {
                            name:"Kavin Kumar",
                            linked:'https://www.linkedin.com/in/ACoAAD08II0B_kKaD5QW2zNKRA7tmkw8p6DA3uc'
                        }
                    ]
                }
            ]
        }
    ]
    return(
        <div className="Achievements" style={{background:achievebg}}>
        <div className="Head_wrap">
                <Header/>
            </div>
            <div className="Achieve">
                <div className="image">
                <img src={Achievement} alt="Achievement" />
                <div className="AchieveContent">
                    <h1>Achievements</h1>
                    <p>Take a look at what are all the achievements our students have done </p>
                    <h2>Learn More</h2>
                </div>
                </div>
            </div>
            {
                datas.map((elem)=>
                    <Box data={elem}/>
                )
            }
            <Footer/>
        </div>
    )
}


export default Achievements